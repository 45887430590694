import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getJoinedApprovedOngoingOpportunities } from "../http/getJoinedApprovedOngoingOpportunities";
import { tasksActions } from "../redux";

export const useGetJoinedApprovedOngoingOpportunities = () => {
  const dispatch = useDispatch();

  const getJoinedApprovedOngoingOpportunitiesFn = () => {
    dispatch(tasksActions.loading);

    getJoinedApprovedOngoingOpportunities()
      .then(({ joinedApprovedOngoingOpportunities }) =>
        dispatch(
          tasksActions.setJoinedApprovedOngoingOpportunities(joinedApprovedOngoingOpportunities)
        )
      )
      .catch((error) => {
        dispatch(tasksActions.error(error));
      });
  };

  useEffect(getJoinedApprovedOngoingOpportunitiesFn, []);
};
