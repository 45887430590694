import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getOpportunityTeamUsers = async (opportunityTeamId: number | undefined) => {
  const accessToken = await acquireAccessToken(msalInstance);
  const responseJson = opportunityTeamId ? await fetch(
    `${process.env.BASE_API_URL}/OpportunityTeamUser/GetOpportunityTeamUsers/${opportunityTeamId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then(response => { return response.json(); }) : undefined;

  console.log("getOpportunityTeamUsers: ", responseJson);

  return {
    opportunityTeamUsers: responseJson,
  };
};