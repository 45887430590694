import React from "react";

import LogoWhite from "assets/icons/Logo-White.svg";
import ArrowRight from "assets/icons/Arrow-Right.svg";

import * as styles from "./JoinedApprovedOngoingOpportunity.styles";
import { Link } from "gatsby";
import { format } from "date-fns";

interface Props {
  joinedApprovedOngoingOpportunity: JoinedApprovedOngoingOpportunity;
}

const JoinedApprovedOngoingOpportunity: React.FC<Props> = ({
  joinedApprovedOngoingOpportunity: {
    id,
    title,
    startDate,
    endDate,
    opportunityManagers,
    avatarFileUrl,
    numberOfVolunteers,
    numberOfTeams,
  },
}) => {
  return (
    <article css={styles.wrapper}>
      <header css={styles.header}>
        <figure css={styles.figure}>
          {avatarFileUrl && (
            <img src={avatarFileUrl} alt="Opportunity avatar" />
          )}
          {!avatarFileUrl && <LogoWhite />}
        </figure>
        <section css={styles.info}>
          <h3 css={styles.title}>{title}</h3>
          <span css={styles.date}>
            <time>{format(new Date(startDate), "dd.MM.yyyy.")}</time> -{" "}
            <time>{format(new Date(endDate), "dd.MM.yyyy.")}</time>
          </span>
        </section>
      </header>
      <section css={styles.body}>
        <dl css={styles.descriptionList}>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>Opportunity manager</dt>
            <dd css={styles.description}>{opportunityManagers?.join(", ")}</dd>
          </div>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>Volunteers</dt>
            <dd css={styles.description}>{numberOfVolunteers}</dd>
          </div>
          <div css={styles.dlWrapper} role="presentation">
            <dt css={styles.term}>Teams</dt>
            <dd css={styles.description}>{numberOfTeams}</dd>
          </div>
        </dl>
      </section>
      <footer css={styles.footer}>
        <Link css={styles.link} to={`${id}`}>
          <ArrowRight />
        </Link>
      </footer>
    </article>
  );
};

export default JoinedApprovedOngoingOpportunity;
