import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOpportunityTasks } from "../http/getOpportunityTasks";
import { tasksActions } from "../redux";

export const useGetOpportunityTasks = (opportunityId: number) => {
  const dispatch = useDispatch();

  const getOpportunityTasksFn = () => {
    dispatch(tasksActions.loading);

    getOpportunityTasks(opportunityId)
      .then(({ opportunityTasks }) =>
        dispatch(
          tasksActions.setOpportunityTasks(opportunityTasks)
        )
      )
      .catch((error) => {
        dispatch(tasksActions.error(error));
      });
  };

  useEffect(getOpportunityTasksFn, []);
};
