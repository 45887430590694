import { RouteComponentProps } from "@reach/router";
import JoinedApprovedOngoingOpportunity from "components/JoinedApprovedOngoingOpportunity/JoinedApprovedOngoingOpportunity";
import NoResults from "components/NoResults/NoResults";
import { SectionTitleDescription } from "components/SectionTitleDescription/SectionTitleDescription";
import React from "react";
import { useSelector } from "react-redux";
import { useGetJoinedApprovedOngoingOpportunities } from "../hooks/useGetJoinedApprovedOngoingOpportunities";
import { tasksSelector } from "../redux";

const JoinedApprovedOngoingOpportunities: React.FC<
  RouteComponentProps
> = () => {
  useGetJoinedApprovedOngoingOpportunities();
  const joinedApprovedOngoingOpportunities = useSelector(
    tasksSelector.selectJoinedApprovedOngoingOpportunities
  );

  return (
    <>
      <SectionTitleDescription
        title="Tasks"
        description="Check out your daily opportunities tasks and collaborate with your teammates."
        onPrimary={false}
      />

      {joinedApprovedOngoingOpportunities.length > 0 ? (
        joinedApprovedOngoingOpportunities?.map(
          (joinedApprovedOngoingOpportunity) => (
            <JoinedApprovedOngoingOpportunity
              joinedApprovedOngoingOpportunity={
                joinedApprovedOngoingOpportunity
              }
              key={joinedApprovedOngoingOpportunity.id}
            />
          )
        )
      ) : (
        <NoResults />
      )}
    </>
  );
};

export default JoinedApprovedOngoingOpportunities;
