import { SEO } from "components/SEO";
import TasksPage from "modules/tasks/TasksPage";
import React from "react";

const Tasks: React.FC = () => {
  return (
    <>
      <SEO title="Tasks" />
      <TasksPage />
    </>
  );
};

export default Tasks;