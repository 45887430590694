import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getJoinedApprovedOngoingOpportunities = async () => {
  const accessToken = await acquireAccessToken(msalInstance);
  const response = await fetch(
    `${process.env.BASE_API_URL}/Opportunity/GetJoinedApprovedOngoingOpportunities`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { opportunities } = await response.json();

  console.log("getJoinedApprovedOngoingOpportunities: ", opportunities);

  return {
    joinedApprovedOngoingOpportunities: opportunities,
  };
};