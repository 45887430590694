import { navigate, RouteComponentProps, useParams } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import React, { useEffect, useState } from "react";
import * as styles from "../../opportunities/styles/CreateOpportunity.styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { getOpportunityTeams } from "../http/getOpportunityTeams";
import { getOpportunityTeamUsers } from "../http/getOpportunityTeamUsers";
import { saveCreateTask } from "../http/saveCreateTask";

const CreateTask: React.FC<RouteComponentProps> = () => {
  let { opportunityId } = useParams();
  const [opportunityTeamId, setOpportunityTeamId] = useState<number | undefined>(undefined);
  const [userProfleId, setUserProfileId] = useState<number | undefined>(undefined);

  const [opportunityTeams, setOpportunityTeams] = useState<GetOpportunityTeamsForOpportunity | undefined>(undefined);
  const [opportunityTeamUsers, setOpportunityTeamUsers] = useState<GetOpportunityTeamUsers | undefined>(undefined);

  const { register, handleSubmit } =
    useForm<CreateOpportunityTask>();

  const onSubmit: SubmitHandler<CreateOpportunityTask> = (data) => {
    data.opportunityId = opportunityId;
    saveCreateTask(data).then(() => navigate(`/tasks/${opportunityId}`));
  };

  useEffect(() => {
    getOpportunityTeams(opportunityId).then(result => { setOpportunityTeams(result.opportunityTeams) });
  }, []);

  useEffect(() => {
    if (opportunityTeamId) {
        getOpportunityTeamUsers(opportunityTeamId).then(result => { setOpportunityTeamUsers(result.opportunityTeamUsers) });
    } else {
        setOpportunityTeamUsers(undefined);
        setUserProfileId(undefined);
    }
  }, [opportunityTeamId]);

  return (
    <OpportunityLayout
        linkLabel="Back"
        linkUrl={`/tasks/${opportunityId}`}>
      <article css={styles.pageWrapper}>
        <h1 css={styles.title}>Create new task</h1>
        <section>
          <form onSubmit={handleSubmit(onSubmit)} id="create-opportunity-form">
            <div className="input__group" role="presentation">
              <label className="input__label" htmlFor="description">
                Task Description
              </label>
              <textarea
                css={styles.textarea}
                rows={10}
                className="input__field"
                id="description"
                placeholder="Write your task description here"
                {...register("description", {
                  required: true,
                  maxLength: 2000,
                })}
              />
            </div>

            <div className="input__group" role="presentation">
              <select className="input__field" id="team" required defaultValue={""} value={opportunityTeamId} onChange={e => setOpportunityTeamId(parseInt(e.currentTarget.value, 10))}>
                <option value="" disabled>Team</option>
                {opportunityTeams?.teams?.map((team) => (
                    <option key={team.id} value={team.id}>{team.name}</option>
                ))}
              </select>
            </div>

            <div className="input__group" role="presentation">
              <select className="input__field" id="assignee" required defaultValue={""} value={userProfleId} { ...register("assignedUserProfileId", { onChange: (e) => setUserProfileId(parseInt(e.currentTarget.value, 10)) }) }>
              <option value="" disabled>Assignee</option>
                {opportunityTeamUsers?.users?.map((user) => (
                    <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              form="create-opportunity-form"
              className="btn btn--primary btn--block">
              Create Task
            </button>
          </form>
        </section>
      </article>
    </OpportunityLayout>
  );
};

export default CreateTask;