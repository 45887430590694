import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getOpportunityTeams = async (opportunityId: number) => {
  const accessToken = await acquireAccessToken(msalInstance);
  const responseJson = await fetch(
    `${process.env.BASE_API_URL}/OpportunityTeam/GetOpportunityTeamsForOpportunity/${opportunityId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then(response => { return response.json(); });

  console.log("getOpportunityTeams: ", responseJson);

  return {
    opportunityTeams: responseJson,
  };
};