import { AuthenticatedTemplate } from "@azure/msal-react";
import { RouteComponentProps, Router } from "@reach/router";
import Layout from "components/layout/Layout";
import React from "react";
import CreateTask from "./components/CreateTask";
import JoinedApprovedOngoingOpportunities from "./components/JoinedApprovedOngoingOpportunities";
import OpportunityTasks from "./components/OpportunityTasks";
import ViewTask from "./components/ViewTask";

const TasksPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <AuthenticatedTemplate>
        <section className="opportunities-content">
          <div
            className="opportunity-content__container container"
            role="presentation">
            <Router>
              <ViewTask path="/tasks/:opportunityId/view/:opportunityTaskId" />
              <CreateTask path="/tasks/:opportunityId/create" />
              <OpportunityTasks path="/tasks/:opportunityId" />
              <JoinedApprovedOngoingOpportunities path="/tasks" />
            </Router>
          </div>
        </section>
      </AuthenticatedTemplate>
    </Layout>
  );
};

export default TasksPage;