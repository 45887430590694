import { css } from "@emotion/react";

export const wrapper = css`
  display: grid;
  grid-template-columns: var(--grid-columns-tablet);
  column-gap: var(--grid-gutter-tablet);
  row-gap: var(--grid-gutter-desktop);
  border-bottom: 1px solid var(--color-decoration-grey);
  padding-block-end: var(--spacing-16);
  margin-block-end: var(--spacing-16);

  @media screen and (min-width: 48rem) {
    grid-template-columns: var(--grid-columns-desktop);
    column-gap: var(--grid-gutter-desktop);
  }
`;

export const header = css`
  display: flex;
  column-gap: 2.125rem;
  grid-column: 1 / -1;

  @media screen and (min-width: 60rem) {
    grid-column: 1 / span 5;
  }
`;

export const figure = css`
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

export const info = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-1);
`;

export const title = css`
  color: var(--color-primary);
  font-family: var(--font-palanquin);
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-36);
`;

export const date = css`
  color: var(--color-secondary);
  font-family: var(--font-roboto);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(---line-height-24);
`;

export const body = css`
  grid-column: 1 / -1;

  @media screen and (min-width: 60rem) {
    grid-column: 7 / span 8;
  }
`;

export const descriptionList = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-2);

  @media screen and (min-width: 35rem) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const dlWrapper = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-2);
`;

export const term = css`
  color: var(--color-tertiary);
  font-family: var(--font-roboto);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-21);
  text-transform: uppercase;
`;

export const description = css`
  color: var(--color-primary);
  font-family: var(--font-roboto);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-21);

  &[data-term="status"]::before {
    display: inline-flex;
    content: "";
    width: var(--spacing-2);
    height: var(--spacing-2);
    aspect-ratio: 1;
    background-color: var(--color-tertiary);
    border-radius: 50%;
    margin-inline-end: var(--spacing-2);
  }

  &[data-term="status"][data-description="Draft"]::before {
    background-color: var(--color-primary);
  }
`;

export const footer = css`
  grid-column: 1 / -1;

  @media screen and (min-width: 60rem) {
    grid-column: -1 / -2;
  }
`;

export const link = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-6);
  height: var(--spacing-6);
  aspect-ratio: 1;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid transparent;
  transition: border-color 0.25s ease-in;

  &:hover {
    border-color: var(--color-primary);
  }
`;
