import { navigate, RouteComponentProps, useParams } from "@reach/router";
import OpportunityLayout from "components/layout/OpportunityLayout/OpportunityLayout";
import React, { useEffect, useState } from "react";
import * as styles from "../../opportunities/styles/CreateOpportunity.styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { getOpportunityTeams } from "../http/getOpportunityTeams";
import { getOpportunityTeamUsers } from "../http/getOpportunityTeamUsers";
import { getOpportunityTask } from "../http/getOpportunityTask";
import { saveAssignTaskToUser } from "../http/saveAssignTaskToUser";
import { saveSetOpportunityTaskToToDoStatus } from "../http/saveSetOpportunityTaskToDoneStatus";
import { saveSetOpportunityTaskToDoneStatus } from "../http/saveSetOpportunityTaskToToDoStatus";
import { saveSetOpportunityTaskToInProgressStatus } from "../http/saveSetOpportunityTaskToInProgressStatus";

const ViewTask: React.FC<RouteComponentProps> = () => {
  let { opportunityId, opportunityTaskId } = useParams();
  const [opportunityTask, setOpportunityTask] = useState<GetOpportunityTask | undefined>(undefined);

  const [opportunityTeamId, setOpportunityTeamId] = useState<number | undefined>(undefined);
  const [userProfleId, setUserProfileId] = useState<number | undefined>(undefined);

  const [opportunityTeams, setOpportunityTeams] = useState<GetOpportunityTeamsForOpportunity | undefined>(undefined);
  const [opportunityTeamUsers, setOpportunityTeamUsers] = useState<GetOpportunityTeamUsers | undefined>(undefined);

  const { register, handleSubmit } =
    useForm<AssignOpportunityTaskToUser>();

  const onSubmit: SubmitHandler<AssignOpportunityTaskToUser> = (data) => {
    if (opportunityTask !== undefined) {
        data.opportunityTaskId = opportunityTaskId;
        data.updatedDate = opportunityTask?.updatedDate;
        saveAssignTaskToUser(data).then(() => navigate(`/tasks/${opportunityId}`));
    }
  };

  useEffect(() => {
    getOpportunityTask(opportunityTaskId).then(result => { setOpportunityTask(result.opportunityTask) });
  }, []);

  useEffect(() => {
    if (opportunityTask !== undefined) {
        getOpportunityTeams(opportunityId).then(result => {
            setOpportunityTeams(result.opportunityTeams)
            if (opportunityTask?.assignedOpportunityTeamId) {
                setOpportunityTeamId(opportunityTask?.assignedOpportunityTeamId)
            }
        });
    }
  }, [opportunityTask]);

  useEffect(() => {
    if (opportunityTeamId !== undefined) {
        getOpportunityTeamUsers(opportunityTeamId).then(result => {
            setOpportunityTeamUsers(result.opportunityTeamUsers)
            if (opportunityTask?.assignedUserProfileId) {
                setUserProfileId(opportunityTask?.assignedUserProfileId)
            }
        });
    } else {
        setOpportunityTeamUsers(undefined);
        setUserProfileId(undefined);
    }
  }, [opportunityTeamId]);

  const onToDoClick = () => {
    if (opportunityTask !== undefined) {
      saveSetOpportunityTaskToToDoStatus({ opportunityTaskId: opportunityTaskId, updatedDate: opportunityTask?.updatedDate }).then(() => navigate(`/tasks/${opportunityId}`));
    }
  }

  const onInProgressClick = () => {
    if (opportunityTask !== undefined) {
      saveSetOpportunityTaskToInProgressStatus({ opportunityTaskId: opportunityTaskId, updatedDate: opportunityTask?.updatedDate }).then(() => navigate(`/tasks/${opportunityId}`));
    }
  }

  const onDoneClick = () => {
    if (opportunityTask !== undefined) {
      saveSetOpportunityTaskToDoneStatus({ opportunityTaskId: opportunityTaskId, updatedDate: opportunityTask?.updatedDate }).then(() => navigate(`/tasks/${opportunityId}`));
    }
  }

  return (
    <OpportunityLayout
        linkLabel="Back"
        linkUrl={`/tasks/${opportunityId}`}>
      <article css={styles.pageWrapper}>
        <header className="profile-content__header profile-content__header--with-heading">
        <p className="profile-content__description">
        {opportunityTask?.description}
        </p>
        <h2 className="profile-content__heading">Assign Task</h2>
        </header>

        <section>
          <form onSubmit={handleSubmit(onSubmit)} id="create-opportunity-form">
            <div className="input__group" role="presentation">
              <select className="input__field" id="team" required value={opportunityTeamId} onChange={e => setOpportunityTeamId(parseInt(e.currentTarget.value, 10))}>
                <option value="" disabled>Team</option>
                {opportunityTeams?.teams?.map((team) => (
                    <option key={team.id} value={team.id}>{team.name}</option>
                ))}
              </select>
            </div>

            <div className="input__group" role="presentation">
              <select className="input__field" id="assignee" required value={userProfleId} { ...register("userProfileId", { onChange: (e) => setUserProfileId(parseInt(e.currentTarget.value, 10)) }) }>
              <option value="" disabled>Assignee</option>
                {opportunityTeamUsers?.users?.map((user) => (
                    <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              form="create-opportunity-form"
              className="btn btn--primary btn--block">
              Assign
            </button>
          </form>
        </section>

        <section>
        <h2 className="profile-content__heading">Move</h2>
        <button
            type="button"
            className="btn btn--block"
            onClick={onToDoClick}>
            To Do
        </button>
        <button
            type="button"
            className="btn btn--block"
            onClick={onInProgressClick}>
            In Progress
        </button>
        <button
            type="button"
            className="btn btn--block"
            onClick={onDoneClick}>
            Done
        </button>
        </section>
      </article>
    </OpportunityLayout>
  );
};

export default ViewTask;
